@import './mixins/mixins.scss';
@import './mixins/scrollbar.scss';
@import './variables.scss';

body {
  background-color: $color-bg;
  font-family: Gilroy, sans-serif;
}

::placeholder {
  color: #B9B9B9;
}

.separator {
  height: 1px;
  background-color: $color-tab-line;
  margin-top: 35px;
  margin-bottom: 30px;
}

.separator-mini {
  background-color: #F1F1F1;
  margin-top: 25px;
  margin-bottom: 25px;
}

.content {
  background-color: #fff;
  padding: 34px 40px;
  border-radius: 8px;
  margin-bottom: 20px;

  @include sm {
    padding: 15px 20px;
  }
}

.sticky {
  position: sticky;
  top: 0;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
}

a {
  text-decoration: none;
}
