$color-text-base: #000000;
$color-text-grey: #737373;

$color-white: #FFFFFF;
$color-orange: #FF7400;
$color-green: #84D651;
$color-yellow: #FFB800;
$color-red: #FD0B36;
$color-save: #80BA45;

$color-bg: #FAF7F7;

$color-line: #F1F1F1;
$color-tab-line: #D5D5D5;
$color-tab-line-active: #F8F8F8;

$button-color: $color-orange;
$button-color-hover: #FF8722;
$button-color-active: #F95A00;
$button-color-disabled: #CCCCCC;

$border-color: $color-tab-line;

$border-radius: 8px;

$screen-xs: 575px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1280px;
$screen-xlg: 1440px;
