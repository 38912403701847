@function spacing($args...) {
  $unit: 4;
  $result: null;
  @each $_ in $args {
    $result: $result #{$_ * $unit}px;
  }
  @return $result;
}


@mixin box {
  background: $color-white;
  border-radius: $border-radius;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.05);

  @include sm {
    border-radius: 0;
    box-shadow: none;
  }
}

@mixin link {
  color: $color-orange;

  &:hover {
    cursor: pointer;
  }
}

@mixin xs {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

@mixin xlg {
  @media (max-width: #{$screen-xlg}) {
    @content;
  }
}

@mixin mediaQuery($size) {
  @media (max-width: #{$size}) {
    @content;
  }
}

@mixin fullHeight {
  height: 100vh;
  min-height: 100vh;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
  }
}
